import React from 'react'
import { Container } from 'react-awesome-styled-grid'
import { Block, Title } from '@superlogica/design-ui'

import * as S from './styled'
import { BigNumber } from 'components/app'

const SectionBigNumbers = () => (
  <S.SectionBigNumbers>
    <Container>
      <Block
        display="grid"
        gridTemplateColumns={{ xxxs: '1fr', md: 'auto 580px' }}
        gap={{ xxxs: 0, md: 68 }}
        alignItems="center"
      >
        <Block tag="header" maxWidth={450}>
          <Title
            font="heading"
            size="display02"
            color="babar900"
            secondColor="sonic500"
            marginBottom={24}
          >
            Milhares de administradoras e imobiliárias confiam na{' '}
            <span>plataforma Superlógica</span>
          </Title>
        </Block>
        <Block
          tag="article"
          display="grid"
          gridTemplateColumns={{ xxxs: '1fr', md: '1fr 1fr' }}
          gap={{ xxxs: 32, md: 60 }}
        >
          <BigNumber
            bignumber="+100mil"
            description="condomínios utilizam nossas soluções"
          />
          <BigNumber
            bignumber="+50%"
            description="Do mercado endereçável
conta com a nossa tecnologia"
          />
          <BigNumber
            bignumber="+3mil"
            description="Administradoras escolhem nosso serviço"
          />
          <BigNumber bignumber="+4mil" description="Imobiliárias parceiras" />
        </Block>
      </Block>
    </Container>
  </S.SectionBigNumbers>
)

export default SectionBigNumbers
