import styled, { css } from 'styled-components'
import { parseColor, Title } from '@superlogica/design-ui'

export const Wrapper = styled.div``

export const BigNumber = styled(Title)`
  font-size: 4.5rem;
  line-height: 1;

  ${({ theme: { colors } }) => css`
    background: -webkit-linear-gradient(
      ${parseColor(colors.sonic500)},
      ${parseColor(colors.sonic300)}
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `}
`
