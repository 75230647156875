import React from 'react'
import { Container } from 'react-awesome-styled-grid'
import { Block, Title } from '@superlogica/design-ui'
import { StaticImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import { PressSlide, PressProps } from 'components/app'

import * as S from './styled'

interface PressDataProps {
  allTestimoniesJson: {
    nodes: PressProps[]
  }
}

const SectionLifetime = () => {
  const { allTestimoniesJson } = useStaticQuery<PressDataProps>(
    graphql`
      query {
        allTestimoniesJson {
          nodes {
            year
            title
            desc
            id
          }
        }
      }
    `
  )

  return (
    <S.SectionLifetime>
      <Container>
        <Block display="flex" flexDirection="column" alignItems="flex-end">
          <Block width={{ xxxs: '100%', md: '50%' }}>
            <Block
              padding={{ xxxs: '40px 0', md: '100px 80px' }}
              bgColor="babar25"
              className="card"
            >
              <Title
                font="heading"
                size="display01"
                color="babar900"
                secondColor="sonic500"
              >
                A história que <span>construímos juntos</span>
              </Title>
            </Block>
            <Block
              padding={{ xxxs: '40px 0', md: '100px 80px' }}
              bgColor="sonic500"
              className="card"
            >
              <PressSlide items={allTestimoniesJson.nodes} />
            </Block>
          </Block>
        </Block>
      </Container>
      <S.ImageWrapper>
        <StaticImage
          placeholder="blurred"
          layout="fullWidth"
          src="../../assets/images/home/image-lifetime.png"
          objectFit="cover"
          objectPosition="center"
          style={{
            width: '100%',
            height: '100%'
          }}
          alt="Um homem e uma mulher"
        />
      </S.ImageWrapper>
    </S.SectionLifetime>
  )
}

export default SectionLifetime
