import styled, { css } from 'styled-components'
import { media, parseColor } from '@superlogica/design-ui'

export const Wrapper = styled.div``
export const InnerSection = styled.div``
export const Content = styled.div``
export const Heading = styled.div``
export const Card = styled.div``
export const ImageWrapper = styled.div``
export const ElementWrapper = styled.div``

export const SectionHero = styled.section`
  position: relative;
  overflow: hidden;
  min-height: 680px;
  display: flex;
  align-items: center;

  ${ImageWrapper} {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.babar25)};

    ${Card} {
      background-image: linear-gradient(
        20deg,
        ${parseColor(colors.sonic500)},
        ${parseColor(colors.sonic400)},
        ${parseColor(colors.sonic300)}
      );
    }
  `}

  ${media.lessThan('md')} {
    ${ImageWrapper} {
      width: 100%;
      position: initial;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
      overflow: hidden;
    }
  }
`

export const SectionHeroCards = styled.section`
  padding: 40px 0 60px;
  position: relative;

  ${Card} {
    padding: 1.3rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
  }

  ${ElementWrapper} {
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: -50px;
    left: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.babar25)};

    ${Card} {
      background-image: linear-gradient(
        20deg,
        ${parseColor(colors.sonic500)},
        ${parseColor(colors.sonic400)},
        ${parseColor(colors.sonic300)}
      );
    }
  `}
`

export const SectionPlatform = styled.section`
  padding: 120px 0 40px;

  ${ImageWrapper} {
    flex: 1;
    overflow: hidden;
  }

  ${Content} {
    flex: 1;
    padding: 48px;
    z-index: 2;
  }

  ${({ theme: { colors } }) => css``}

  ${media.lessThan('sm')} {
    padding: 60px 0 30px;

    ${Content} {
      padding: 24px;
    }
  }
`

export const SectionBigNumbers = styled.section`
  padding: 40px 0 80px;

  ${media.lessThan('md')} {
    padding: 40px 0 60px;
  }
`

export const SectionLifetime = styled.section`
  position: relative;
  overflow: hidden;

  ${ImageWrapper} {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .card {
    position: relative;
    &:after {
      content: '';
      width: 100vw;
      height: 100%;
      position: absolute;
      top: 0;
      right: -100vw;
      background-color: inherit;
    }
  }

  ${media.lessThan('sm')} {
    .card {
      &:after,
      :before {
        content: '';
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;
        background-color: inherit;
      }
      &:after {
        right: -100vw;
      }
      :before {
        left: -100vw;
      }
    }
    ${ImageWrapper} {
      width: 100%;
      position: initial;
    }
  }
`

export const SectionOffices = styled.section`
  padding: 80px 0;

  ${media.lessThan('md')} {
    padding: 60px 0;
  }
`

export const SectionContent = styled.section`
  position: relative;
  overflow: hidden;

  ${ElementWrapper} {
    position: absolute;
    width: 100%;
    height: 150%;
    top: -25%;
    left: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.babar25)};
  `}
`

export const SectionSubscribe = styled.section`
  padding: 120px 0;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    backdrop-filter: blur(100px);
  }

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.sonic600)};
    background-image: linear-gradient(
      40deg,
      ${parseColor(colors.sonic600)},
      ${parseColor(colors.sonic200)},
      ${parseColor(colors.sonic300)},
      ${parseColor(colors.sonic600)}
    );
  `}

  ${media.lessThan('md')} {
    padding: 60px 0;
  }
`
