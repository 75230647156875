import React from 'react'
import { Container } from 'react-awesome-styled-grid'
import { Title, Text, Block } from '@superlogica/design-ui'

import * as S from './styled'
import { StaticImage } from 'gatsby-plugin-image'

const SectionPlatform = () => (
  <S.SectionPlatform>
    <Container>
      <Block
        display="grid"
        alignItems="center"
        gridTemplateColumns={{ xxxs: '1fr', md: '1fr 1fr' }}
        gap={{ xxxs: 40, md: 68 }}
      >
        <S.ImageWrapper>
          <StaticImage
            placeholder="blurred"
            layout="fullWidth"
            src="../../assets/images/home/image-plataform.png"
            objectFit="cover"
            objectPosition="contain"
            style={{
              width: '100%',
              height: '100%'
            }}
            alt="Três pessoas conversando enquanto olham para o computador"
          />
        </S.ImageWrapper>
        <S.Content>
          <Title
            font="heading"
            size="display01"
            color="babar900"
            secondColor="sonic500"
            marginBottom={24}
          >
            As <span>soluções tecnológicas</span> da transformação digital do
            mercado do morar
          </Title>
          <Text color="babar800">
            Somos a mais completa plataforma tecnológica, com serviços
            financeiros integrados de verdade e com uma visão de mercado que
            anda junto com o sucesso dos negócios de administradoras de
            condomínios e imobiliárias.
          </Text>
        </S.Content>
      </Block>
    </Container>
  </S.SectionPlatform>
)

export default SectionPlatform
