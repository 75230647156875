import React from 'react'
import { Container } from 'react-awesome-styled-grid'
import { Block, Button, Text, Title, Vector } from '@superlogica/design-ui'

import * as S from './styled'

const SectionOffices = () => (
  <S.SectionOffices>
    <Container>
      <Block
        display="grid"
        gridTemplateColumns={{ xxxs: '1fr', md: 'auto 1fr' }}
        gap={{ xxxs: 32, md: 68 }}
      >
        <Block>
          <Title font="heading" size="display01" color="babar900">
            Escritórios
          </Title>
        </Block>
        <Block
          display="grid"
          gridTemplateColumns={{ xxxs: '1fr', md: '1fr 1fr 1fr' }}
          gap={24}
        >
          <Block>
            <Title
              font="heading"
              size="display04"
              color="babar900"
              marginBottom={8}
            >
              Barueri
            </Title>
            <Text color="babar700" size="body02">
              Alameda Rio Negro, 585 Bloco B - 3º andar - CEP. 06454-000 -
              Alphaville, Barueri - SP
            </Text>
          </Block>
          <Block>
            <Title
              font="heading"
              size="display04"
              color="babar900"
              marginBottom={8}
            >
              Campinas
            </Title>
            <Text color="babar700" size="body02" marginBottom={8}>
              Rua Joaquim Vilac nº 501, Vila Teixeira - CEP. 13.032 - 385 -
              Campinas - SP
            </Text>
            <Button
              tag="a"
              href="https://drive.google.com/drive/u/0/folders/1vuWJHBWFPF2bjYEluhhf2bWoATTK2gWN"
              target="_blank"
              title="Baixar fotos"
              appearance="link"
              color="sonic500"
              rightIcon={<Vector name="arrow-right-2" color="sonic500" />}
              weight="semiBold"
            >
              Baixar fotos
            </Button>
          </Block>
          <Block>
            <Title
              font="heading"
              size="display04"
              color="babar900"
              marginBottom={8}
            >
              Londrina
            </Title>
            <Text color="babar700" size="body02">
              Rua Bento Munhoz da Rocha Neto, nº 685, Jardim do Lago, Londrina
            </Text>
          </Block>
        </Block>
      </Block>
    </Container>
  </S.SectionOffices>
)

export default SectionOffices
