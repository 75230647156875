import React, { useRef } from 'react'
import { Block, Carousel, Text, Title, Vector } from '@superlogica/design-ui'

import * as S from './PressSlide.styled'

export interface PressProps {
  year: string
  title: string
  desc: string
}

export interface PressSlideProps {
  items?: PressProps[]
}

const PressSlide: React.FC<PressSlideProps> = ({ items = [] }) => {
  const swiperRef = useRef<any>()

  return (
    <S.Wrapper>
      <S.InnerWrapper>
        <Carousel
          fullMobile
          themeColor="babar900"
          swiperRef={swiperRef}
          bulletColor="babar0"
          activeBulletColor="babar0"
          swiper={{
            autoplay: {
              delay: 4000
            },
            observer: true,
            loop: true,
            watchOverflow: true,
            simulateTouch: false,
            slidesPerView: 1,
            pagination: {
              clickable: true
            }
          }}
        >
          {items.map(({ year, title, desc }, i) => (
            <Block key={`${year}-${i}`}>
              <Text
                font="heading"
                size="display02"
                color="sonic50"
                marginBottom={16}
              >
                {year}
              </Text>
              <Title
                font="heading"
                size="display03"
                color="babar0"
                weight="bold"
                marginBottom={8}
              >
                {title}
              </Title>
              <Text color="babar0">{desc}</Text>
            </Block>
          ))}
        </Carousel>

        <S.ButtonPrev onClick={() => swiperRef.current.swiper.slidePrev()}>
          <Vector name="arrow-left-2" width={20} height={20} />
        </S.ButtonPrev>
        <S.ButtonNext onClick={() => swiperRef.current.swiper.slideNext()}>
          <Vector name="arrow-right-2" width={20} height={20} />
        </S.ButtonNext>
      </S.InnerWrapper>
    </S.Wrapper>
  )
}

export default PressSlide
