import React from 'react'
import { Container } from 'react-awesome-styled-grid'
import { Block, Button, Text, Title, Vector } from '@superlogica/design-ui'

import * as S from './styled'

const SectionHeroCards = () => (
  <S.SectionHeroCards>
    <Container>
      <Block
        display="grid"
        gap={24}
        gridTemplateColumns={{
          xxxs: '1fr',
          sm: '1fr 1fr',
          md: 'repeat(4, 1fr)'
        }}
        position="relative"
        zIndex={4}
      >
        <S.Card>
          <Block>
            <Title
              font="heading"
              color="babar0"
              size="display03"
              weight="semiBold"
              marginBottom={8}
            >
              Release
            </Title>
            <Text color="babar0">
              Encontre as novidades sobre o grupo e conheça nossos porta-vozes
            </Text>
          </Block>
          <Button
            tag="a"
            target="_blank"
            color="babar0"
            appearance="link"
            href="https://blog.superlogica.com/imprensa/"
            title="Saiba mais"
            rightIcon={<Vector name="arrow-right-2" color="sonic500" />}
            weight="semiBold"
          >
            Saiba mais
          </Button>
        </S.Card>
        <S.Card>
          <Block>
            <Title
              font="heading"
              color="babar0"
              size="display03"
              weight="semiBold"
              marginBottom={8}
            >
              Galeria de imagens
            </Title>
            <Text color="babar0">
              Baixe nosso logo, fotos dos porta-vozes e telas dos produtos
            </Text>
          </Block>
          <Button
            tag="a"
            color="babar0"
            appearance="link"
            href="https://blog.superlogica.com/imprensa/porta-vozes/"
            target="_blank"
            title="Saiba mais"
            rightIcon={<Vector name="arrow-right-2" color="sonic500" />}
            weight="semiBold"
          >
            Saiba mais
          </Button>
        </S.Card>
        <S.Card>
          <Block>
            <Title
              font="heading"
              color="babar0"
              size="display03"
              weight="semiBold"
              marginBottom={8}
            >
              Blog Superlógica
            </Title>
            <Text color="babar0">
              Fique por dentro dos principais assuntos do mercado
            </Text>
          </Block>
          <Button
            tag="a"
            color="babar0"
            appearance="link"
            href="https://blog.superlogica.com/"
            target="_blank"
            title="Saiba mais"
            rightIcon={<Vector name="arrow-right-2" color="sonic500" />}
            weight="semiBold"
          >
            Saiba mais
          </Button>
        </S.Card>
        <S.Card>
          <Block>
            <Title
              font="heading"
              color="babar0"
              size="display03"
              weight="semiBold"
              marginBottom={8}
            >
              Insights
            </Title>
            <Text color="babar0">
              Conheça nosso índice de inadimplência locatícia
            </Text>
          </Block>
          <Button
            tag="a"
            color="babar0"
            appearance="link"
            href="https://plataforma.arboimoveis.com.br/inteligencia-imobiliaria/inadimplencia-locaticia"
            target="_blank"
            title="Saiba mais"
            rightIcon={<Vector name="arrow-right-2" color="sonic500" />}
            weight="semiBold"
          >
            Saiba mais
          </Button>
        </S.Card>
      </Block>
    </Container>
    <S.ElementWrapper>
      <Vector name="element-horizontal" color="sonic300" />
    </S.ElementWrapper>
  </S.SectionHeroCards>
)

export default SectionHeroCards
