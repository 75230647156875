import * as React from 'react'

import SectionHero from './Section.Hero'
import SectionHeroCards from './Section.HeroCards'
import SectionPlatform from './Section.Platform'
import SectionBigNumbers from './Section.BigNumbers'
import SectionLifetime from './Section.Lifetime'
import SectionOffices from './Section.Offices'
import SectionContent from './Section.Content'
// import SectionSubscribe from './Section.Subscribe'

const View: React.FC = () => (
  <>
    <SectionHero />
    <SectionHeroCards />
    <SectionPlatform />
    <SectionBigNumbers />
    <SectionLifetime />
    <SectionOffices />
    <SectionContent />
    {/* <SectionSubscribe /> */}
  </>
)

export default View
