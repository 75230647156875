import styled, { css } from 'styled-components'
import { Block, media, parseColor } from '@superlogica/design-ui'

export const ButtonPrev = styled.button``

export const ButtonNext = styled.button``

export const InnerWrapper = styled(Block)`
  position: relative;

  .swiper {
    padding-bottom: 50px;

    .swiper-pagination {
      bottom: 0;
    }
  }

  .sdl-carousel {
    .swiper-slide {
      &:not(.swiper-slide-active) {
        opacity: 0;
      }
    }
  }

  ${ButtonPrev},
  ${ButtonNext} {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    border-radius: 0;
    border: 0;
    outline: 0;

    position: absolute;
    margin: auto;
    top: auto;
    bottom: 0;
    z-index: 10;
  }

  ${ButtonPrev} {
    left: 0;
  }

  ${ButtonNext} {
    right: 0;
  }

  ${({ theme: { colors } }) => css`
    ${ButtonPrev},
    ${ButtonNext} {
      svg {
        color: ${parseColor(colors.babar0)};
      }
    }
  `}

  ${media.lessThan('sm')} {
    max-width: 100%;
    width: 100%;

    .swiper {
      padding-bottom: 40px;

      .swiper-pagination {
        bottom: 0;
      }
    }
  }
`

export const Wrapper = styled.div`
  overflow: hidden;
`
