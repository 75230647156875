import React from 'react'

import { Seo } from 'components/helpers'
import View from 'views/home'

const IndexPage: React.FC = () => (
  <>
    <Seo />
    <View />
  </>
)

export default IndexPage
