import React from 'react'
import { Text } from '@superlogica/design-ui'

import * as S from './BigNumber.styled'

interface BigNumberProps {
  bignumber: string
  description: string
}

const BigNumber: React.FC<BigNumberProps> = ({ bignumber, description }) => {
  return (
    <S.Wrapper>
      <S.BigNumber font="heading">{bignumber}</S.BigNumber>
      <Text size="body02" color="babar900" weight="semiBold" uppercase>
        {description}
      </Text>
    </S.Wrapper>
  )
}

export default BigNumber
