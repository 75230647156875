import styled from 'styled-components'
import { parseColor } from '@superlogica/design-ui'
import { rem } from 'polished'
import { BannerProps } from '..'

type WrapperProps = Required<Pick<BannerProps, 'color'>>

export const Image = styled.img`
  border-radius: 4px;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
`

export const Figure = styled.figure`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;

  .gatsby-image-wrapper {
    img {
      transition: transform 400ms ease-in-out;
    }
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      0,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`

export const Badge = styled.span`
  padding: 6px 10px;
  border-radius: 50vh;
  font-size: ${rem(10)};
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  align-self: flex-start;
  margin-bottom: auto;
`

export const Caption = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 28px 32px 32px;
  height: 100%;
`

export const Wrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['color'].includes(prop)
})<WrapperProps>`
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  transition: border-color 300ms ease-in-out;

  ${({ theme, color }) => `
    background-color: ${parseColor(theme.colors.babar100)};
    border-bottom: 4px solid ${parseColor(theme.colors[color])};

    ${Badge} {
      background-color: ${parseColor(theme.colors[color])};
      color: ${parseColor(theme.colors.babar0)};
    }
  `}

  &:hover {
    .gatsby-image-wrapper {
      img {
        transform: scale(1.1);
      }
    }
  }
`
