import React from 'react'
import { Container } from 'react-awesome-styled-grid'
import { Block, Button, Text, Title, Vector } from '@superlogica/design-ui'

import * as S from './styled'
import { StaticImage } from 'gatsby-plugin-image'

const SectionContent = () => (
  <S.SectionContent>
    <Container>
      <Block
        display="grid"
        gridTemplateColumns={{ xxxs: '1fr', md: '1fr 560px' }}
        padding={{ xxxs: '60px 0', md: '80px 0' }}
        alignItems="center"
        gap={{ xxxs: 60, md: 80 }}
        position="relative"
        zIndex={3}
      >
        <Block tag="header">
          <Title
            font="heading"
            size="display01"
            color="babar900"
            secondColor="sonic500"
            marginBottom={24}
          >
            Nossos <span>conteúdos</span>
          </Title>
          <Text size="body04" color="babar700">
            Confira a seleção de materiais produzidos pela Superlógica acerca
            dos mercados condominial e imobiliário, tecnologia e
            empreendedorismo
          </Text>
        </Block>
        <Block tag="article">
          <Block overflow="hidden" borderRadius={8} bgColor="babar0">
            <Block>
              <StaticImage
                placeholder="blurred"
                layout="fullWidth"
                src="../../assets/images/home/image-card-content.png"
                objectFit="cover"
                objectPosition="center"
                style={{
                  width: '100%',
                  height: '100%'
                }}
                alt="Banner Next 2024"
              />
            </Block>
            <Block padding={32}>
              <Title
                font="heading"
                size="display02"
                color="babar900"
                marginBottom={24}
              >
                Superlógica apresenta novidades para o Next 2024
              </Title>
              <Button
                tag="a"
                appearance="link"
                color="sonic500"
                rightIcon={<Vector name="arrow-right-2" color="sonic500" />}
                href="https://blog.superlogica.com/imprensa/releases/superlogica-apresenta-novidades-para-o-next-2024/"
                target="_blank"
                title="Ver sobre"
                weight="semiBold"
              >
                Ver sobre
              </Button>
            </Block>
          </Block>
        </Block>
      </Block>
    </Container>
    <S.ElementWrapper>
      <Vector name="element-1" color="babar50" />
    </S.ElementWrapper>
  </S.SectionContent>
)

export default SectionContent
