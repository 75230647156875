import React from 'react'
import { Container } from 'react-awesome-styled-grid'
import { Block, Text, Title } from '@superlogica/design-ui'
import { StaticImage } from 'gatsby-plugin-image'

import * as S from './styled'

const SectionHero = () => (
  <S.SectionHero>
    <Container>
      <Block
        position="relative"
        zIndex={3}
        padding="60px 0"
        className="content"
        maxWidth="550px"
      >
        <Title
          tag="h1"
          size="overline"
          font="heading"
          weight="semiBold"
          marginBottom={32}
          letterSpacing="1px"
          uppercase
        >
          Imprensa
        </Title>
        <Title
          tag="h2"
          font="heading"
          size="heading01"
          color="babar900"
          secondColor="sonic500"
          marginBottom={16}
        >
          Conheça a <span>Superlógica</span>
        </Title>
        <Text
          weight="semiBold"
          color="babar700"
          size="display03"
          marginBottom={16}
        >
          Estamos revolucionando a experiência do morar
        </Text>
        <Text color="babar700" size="display05">
          Contato para imprensa: <a href="mailto:">imprensa@superlogica.com</a>
        </Text>
      </Block>
      <S.ImageWrapper>
        <StaticImage
          placeholder="blurred"
          layout="fullWidth"
          src="../../assets/images/home/image-hero.png"
          objectFit="contain"
          objectPosition="top right"
          style={{
            width: '100%',
            height: '100%'
          }}
          alt="Três pessoas conversando enquanto olham para o computador"
        />
      </S.ImageWrapper>
    </Container>
  </S.SectionHero>
)

export default SectionHero
